<template>
    <div class="w-100 bg-white flex items-center justify-center relative" style="z-index: 999999">
        <div style="width: 1111px; height: 100%; overflow-y: hidden">
            <TransactionDetails :transaction="transaction" title="Details" />
        </div>
    </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import TransactionDetails from '@/views/Transactions/widgets/TransactionDetails'

export default {
    name: 'Receipt',
    components: { TransactionDetails },

    setup() {
        const store = useStore()
        const route = useRoute()
        const transaction = computed(() => store?.state?.Transaction?.transaction)

        // const tildoChat = computed(() => document?.getElementById('tidio-chat'))
        //
        // watch(
        //   () => tildoChat.value,
        //   (prev, curr) => {
        //     if (prev !== curr) {
        //       console.log(tildoChat)
        //       tildoChat.value.remove()
        //     }
        //   }
        // )

        onMounted(() => {
            const { orgId, id } = route?.params
            const payload = {}
            payload.orgId = orgId
            payload.reference = id
            store.dispatch('Transaction/getReceipt', payload)
            // console.log('transaction', transaction.value.createdAt)
        })

        return { transaction }
    }
}
</script>

<style scoped></style>
